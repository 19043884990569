import api from "../../apiV1/api";
import validate from "./validate";

const auth = {
  validateSupplier(supplier, is_update = false) {
    const {
      country,
      company_name,
      kra_pin_number,
      supplier_type,
      contact_name,
      location,
      email,
      phone_number,
      terms_and_privacy_policy,
    } = supplier;

    const errors = {};

    const countryInvalid = validate.validateCountry(country);
    if (countryInvalid) errors.country = countryInvalid;

    if (!company_name || typeof company_name !== "string") {
      errors.company_name = "Company Name is required.";
    }

    if (!contact_name || typeof contact_name !== "string") {
      errors.contact_name = "Contact Name is required.";
    }

    const phoneInvalid = validate.validatePhoneNumber({
      phone_number: phone_number,
    });
    if (phoneInvalid) errors.phone_number = phoneInvalid;

    if (!is_update) {
      const supplierTypeInvalid = validate.validateSupplierType(supplier_type);
      if (supplierTypeInvalid) errors.supplier_type = supplierTypeInvalid;

      const taxInvalid = validate.validateTaxNumber({
        kra_pin_number,
        country,
        supplier_type,
      });
      if (taxInvalid) errors.kra_pin_number = taxInvalid;

      if (!location || typeof location !== "string") {
        errors.location = "Location may not be null.";
      }

      const emailInvalid = validate.validateEmailAddress(email);
      if (emailInvalid) errors.email = emailInvalid;

      const tosInvalid = validate.validateTOS(terms_and_privacy_policy);
      if (tosInvalid) errors.terms_and_privacy_policy = tosInvalid;
    }

    return errors;
  },
  supplierRegister(supplier) {
    return new Promise((resolve, reject) => {
      // const phone = validate.validatePhoneNumber({
      //   phone_number: supplier.phone_number,
      //   auto_format: true,
      // });
      let proceed = true;
      // if (phone) {
      //   if (typeof phone === "string") {
      //     reject(["phone_number", [phone]]);
      //     proceed = false;
      //   } else if (typeof phone === "number") {
      //     supplier.phone_number = `+${phone}`;
      //   }
      // }
      if (proceed) {
        api.post(
          "/supplier/register/",
          supplier,
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  },
  activateAccount(token) {
    return new Promise((resolve, reject) => {
      api.get(
        `/authentication/verify_email?token=${token}`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  verifyToken(uidb64, token) {
    return new Promise((resolve, reject) => {
      api.get(
        `/authentication/password-reset/${uidb64}/${token}/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  setPassword(content) {
    return new Promise((resolve, reject) => {
      api.patch(
        `/authentication/password-reset-complete`,
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  login(content) {
    return new Promise((resolve, reject) => {
      api.post(
        "/authentication/login",
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  verifyTwoFA(content) {
    return new Promise((resolve, reject) => {
      api.post(
        `/authentication/tfa/verify/`,
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  resendTwoFA(content) {
    return new Promise((resolve, reject) => {
      api.get(
        `/authentication/tfa/resend/`,
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  resetPassword(content) {
    return new Promise((resolve, reject) => {
      api.post(
        "/authentication/request-reset-email/",
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  requestAccountActivation(content) {
    return new Promise((resolve, reject) => {
      api.post(
        "/authentication/account/activation/",
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  countryLocations(countryName) {
    return new Promise((resolve, reject) => {
      api.get(
        `/core/countries/${countryName}/locations/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  categoryTypes(query) {
    return new Promise((resolve, reject) => {
      api.get(
        `/core/category_types/?query=${query}&page=1&page_size=10`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  companyProfile() {
    return new Promise((resolve, reject) => {
      api.get(
        `/supplier/profile/company/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  updateCompanyProfile(companyID, content) {
    return new Promise((resolve, reject) => {
      api.patch(
        `/supplier/profile/${companyID}/`,
        content,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  supplierPrivileges() {
    return new Promise((resolve, reject) => {
      api.get(
        `/supplier/company/users/my_privileges/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  buyerPrivileges() {
    return new Promise((resolve, reject) => {
      api.get(
        `/buyer/users/my_privileges/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  qedPrivileges() {
    return new Promise((resolve, reject) => {
      api.get(
        `/qed/users/my_privileges/`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  logs(page, dataPerPage) {
    return new Promise((resolve, reject) => {
      api.get(
        `/authentication/user_logs/?page=${page}&page_size=${dataPerPage}`,
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export default auth;
