import { parsePhoneNumber } from "libphonenumber-js";
import { geo } from "@/assets/jsons";

const validate = {
  validateCountry(country) {
    if (!country || typeof country !== "string") {
      return "Country may not be null.";
    }

    const countryNames = geo.Countries.map((e) => e.countryName);
    if (countryNames.find((nation) => nation == country) === undefined) {
      return "Invalid country provided.";
    }
  },

  validateEmailAddress(email) {
    if (!email || typeof email !== "string") {
      return "Email Address is required.";
    } else {
      const validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email.match(validRegex)) {
        return "Invalid email address provided.";
      }
    }
  },

  validatePhoneNumber(
    { phone_number, auto_format = false, extract_iso2code = false } = {
      phone_number: "",
    }
  ) {
    try {
      if (!phone_number || typeof phone_number !== "string") {
        return "Phone Number may not be blank.";
      }
      const phoneNumber = parsePhoneNumber(phone_number);
      if (!phoneNumber) {
        return "Cannot determine the Phone Number";
      }
      if (phoneNumber.constructor.name !== "PhoneNumber") {
        return "Cannot validate the Phone Number";
      }
      if (!phoneNumber.isPossible()) {
        return "This is not a possible phone number.";
      }
      if (!phoneNumber.isValid()) {
        return "This is not a valid phone number.";
      }
      const extractIso2Code =
        extract_iso2code && typeof extract_iso2code === "boolean";
      if (extractIso2Code) return phoneNumber.country;
      const autoFormat = auto_format && typeof auto_format === "boolean";
      if (autoFormat) return parseInt(phoneNumber.number);
    } catch (err) {
      if (err.name === "ParseError") {
        switch (err.message) {
          case "TOO_SHORT":
            return "Phone Number is too short.";
          case "NOT_A_NUMBER":
            return "You have not entered a phone number.";
          /*case "INVALID_COUNTRY":
            return "Phone number is not entered correctly.";*/
          default:
            return `${err.message}`;
        }
      }
      console.error(err);
      return "Phone Number can not be validated";
    }
  },

  validateSupplierType(supplier_type) {
    if (!supplier_type) {
      return "Company Type may not be null.";
    }
    if (typeof supplier_type !== "number") {
      return "Invalid Company Type selected";
    }
  },

  validateTaxNumber({ kra_pin_number, country, supplier_type }) {
    if (!country || typeof country !== "string") {
      return "Unable to validate Tax Identification Number.\nPlease fix issue with country.";
    }

    const isKenyan = country === "Kenya";
    const pin_tag = isKenyan ? "KRA PIN Number" : "Tax Identification Number";

    if (this.validateSupplierType(supplier_type)) {
      return `Unable to validate ${pin_tag}.\nPlease fix issue with company type.`;
    }

    if (!kra_pin_number || typeof kra_pin_number !== "string") {
      return `${pin_tag} may not be null.`;
    } else if (kra_pin_number.length < 5) {
      return `Ensure ${pin_tag} has at least 5 characters.`;
    } else {
      if (isKenyan) {
        const kra_pin_prefix = kra_pin_number.charAt(0).toUpperCase();
        switch (supplier_type) {
          case 2:
            if (kra_pin_prefix !== "A") {
              return "Invalid tax identification number.";
            }
            break;
          case 1:
            if (kra_pin_prefix !== "P") {
              return "Please verify the tax identification number is correct.";
            }
            break;

          default:
            break;
        }
      }
    }
  },

  validateTOS(terms_and_privacy_policy) {
    if (
      !terms_and_privacy_policy ||
      typeof terms_and_privacy_policy != "boolean"
    ) {
      return "You need to agree to Terms of Service and Privacy Policy to continue";
    }
  },
};

export default validate;
